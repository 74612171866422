////////////////////////////////////////
// Scroll To
////////////////////////////////////////

(function(s4d, $, undefined) {


    // Handle clicks on anything with a [scroll-to-selector] attribute
    var scrollToSelectorButtons = document.querySelectorAll('[scroll-to-selector]');

    Array.prototype.forEach.call(scrollToSelectorButtons, function(el, i){

        el.addEventListener('click', function(event){

            event.preventDefault();

            console.log("testing", el.getAttribute('scroll-to-selector'));

            var targetEl = document.querySelector(el.getAttribute('scroll-to-selector'));

            var currentPosition = window.pageYOffset || document.documentElement.scrollTop;

            var contactFormBox = targetEl.getBoundingClientRect();
            var contactFormYPosition = contactFormBox.top + currentPosition;

            var difference = Math.sqrt(Math.pow((contactFormYPosition - currentPosition), 2));
            var duration = Math.sqrt(difference) * 15;

            TinyAnimate.animate(currentPosition, contactFormYPosition -100, duration, function(value){
                document.documentElement.scrollTop = document.body.scrollTop = value;
            }, 'easeOutQuad');

        });

    });

})(window.s4d = window.s4d || {}, jQuery);