////////////////////////////////////////
// Main
////////////////////////////////////////

(function(s4d, $, undefined) {

    // Create 'ready' function which stores code and runs it when the document is ready
    s4d.ready = function(fn){
        if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading"){
            fn();
        } else {
            document.addEventListener('DOMContentLoaded', fn);
        }
    }


    // Check if element is on screen
    s4d.isElementOnScreen = function(el) {
        var bounds = el.getBoundingClientRect();
        return bounds.top < window.innerHeight && bounds.bottom > 0;
    }


    // Start and stop videos when in view
    s4d.autoPlayVideos = function(){

        var videos = document.querySelectorAll('video');

        Array.prototype.forEach.call(videos, function(video, i){

            video.onplaying = function() {
                video.classList.add('is-playing');
            };

            video.onpause = function() {
                video.classList.remove('is-playing');
            };

            if (s4d.isElementOnScreen(video)) {
                if (video.paused && !video.classList.contains('is-playing')) {
                    video.play();
                }
            } else {
                if (!video.paused && video.classList.contains('is-playing')) {
                    video.pause();
                }
            }

        });

    };
    window.addEventListener('scroll', s4d.autoPlayVideos);
    s4d.autoPlayVideos();


    // Init Lazy loading of images and videos
    $(function() {
        $('.lazy').Lazy({
            effect: 'fadeIn',
            effectTime: 150,
            combined: true,
            delay: 5000
        });
    });


    s4d.ready(function(){

        // Show/hide sales mode stuff
        if(s4d.salesMode){
            var els = document.querySelectorAll('[s4d-show="salesMode"]')
            Array.prototype.forEach.call(els, function(el, i){
                el.style.display = "block";
            });
        }
        else{
            var els = document.querySelectorAll('[s4d-show="noSalesMode"]')
            Array.prototype.forEach.call(els, function(el, i){
                el.style.display = "block";
            });
        }


        //Replace elements where necessary
        var els = document.querySelectorAll('[s4d-replace-with]');
        Array.prototype.forEach.call(els, function(el, i){
            var replaceIf = el.getAttribute('s4d-replace-if');
            if(!replaceIf || replaceIf.split('|').indexOf(s4d.operatingSystem) != -1) {
                el.outerHTML = '<img src="' + el.getAttribute('s4d-replace-with') + '" />';
            }
        });
    });


})(window.s4d = window.s4d || {}, jQuery);








        