////////////////////////////////////////
// Contact Forms
////////////////////////////////////////

(function (s4d, $, undefined) {
    
    // Handle Contact Form Submission
    var contactForm = document.querySelector('.contact-form');
    if (contactForm) {

        //Show a validation warning where appropriate
        function validateElement(el, submitting) {
            var valid;
            if (submitting && (el.value === '' || el.value === undefined)) { 
                valid = false;
                el.style.border = 'solid thin red';
                el.parentNode.querySelector('span').style.color = 'red';
            } else {
                valid = true;
                el.style.border = '';
                el.parentNode.querySelector('span').style.color = '';
            }
            return valid;
        }
        
        //Clear validation warning onBlur
        var elListValidation = contactForm.querySelectorAll('input[name="name"], textarea[name="message"]');
        for (var i = 0, len = elListValidation.length; i < len; i++) {
            elListValidation[i].addEventListener('blur', function (event) {
                validateElement(event.target, false);
            })
        }

        //Post form data when submitting
        contactForm.addEventListener('submit', function (event) {

            event.preventDefault();

            // Set loaders spinning
            var loaders = this.querySelectorAll('[submit-loader]:not([submit-loader="false"])');
            for (var i = 0, len = loaders.length; i < len; i++) {
                loaders[i].classList.add('is-loading');
            }

            function stopLoadingSpinners() {
                for (var i = 0, len = loaders.length; i < len; i++) {
                   loaders[i].classList.remove('is-loading');
                }   
            }

            // Identify the form elements
            var elName = contactForm.querySelector('input[name="name"]');
            var elCompany = contactForm.querySelector('input[name="companyName"]');
            var elPhone = contactForm.querySelector('input[name="phoneNumber"]');
            var elEmail = contactForm.querySelector('input[name="emailAddress"]');
            var elMessage = contactForm.querySelector('textarea[name="message"]');
            
            // Validation
            if (!validateElement(elName, true) | !validateElement(elMessage, true)) {
                stopLoadingSpinners();
                return false;
            }

            // Create model to send to back-end
            var model = {
                name: elName.value,
                companyName: elCompany.value,
                phoneNumber: elPhone.value,
                emailAddress: elEmail.value,
                message: elMessage.value
            }

            var errorsDiv = contactForm.parentNode.parentNode.querySelector('.content-block-contact-thanks'); // todo: create another div?

            axios.post('/umbraco/surface/contactform/submit', JSON.stringify(model))
                .then(function (response) {
                    if (response.status === 200) {
                        stopLoadingSpinners();
                        errorsDiv.style.display = 'none';
                        contactForm.parentNode.style.display = 'none';

                        var thanksDiv = contactForm.parentNode.parentNode.querySelector('.content-block-contact-thanks');
                        thanksDiv.innerHtml = '<h1 style="margin-bottom:1em;">THANK YOU</h1><div>We\'ll be in touch as soon as we can.</div>'; //todo: don't do this when there's a dedicated errorsDiv
                        thanksDiv.style.display = 'block'; 

                        _gaq.push(['_trackPageview', '/contactFormGoal.html']);
                    }
                    else {
                        errorsDiv.style.display = 'block';
                        errorsDiv.innerText = response.statusText;
                        console.error(response.statusText);
                    }
                })
                .catch(function (error) {
                    stopLoadingSpinners();
                    errorsDiv.style.display = 'block';
                    errorsDiv.innerText = 'Sorry, something went wrong!';
                    console.error(error);
                });

        });

    }

})(window.s4d = window.s4d || {}, jQuery);