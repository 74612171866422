////////////////////////////////////////
// Maps
////////////////////////////////////////

(function(s4d, $, undefined) {

    
    // Set up Mapbox
    s4d.mapbox = {
        accessToken: 'pk.eyJ1Ijoiam9uNjRkaWdpdGFsIiwiYSI6ImNqNTdhZzB3bTFwMDYzM2xra3dmMG9peGUifQ.VQYB1PHl1m6HKcJOXj8Tsw',
        defaultStyle: 'mapbox://styles/jon64digital/cj57bbrhi36zy2tql475auzjc'
    }

    mapboxgl.accessToken = s4d.mapbox.accessToken;

    // Define settings for each map
    s4d.maps = [
        {
            name: 'london',
            options: {
                container: 'londonOfficeMap',
                style: s4d.mapbox.defaultStyle,
                interactive: false
            },
            startPosition: {
                center: [-0.163636, 51.5196956],
                bearing: 210,
                pitch: 45,
                zoom: 10
            },
            endPosition: {
                center: [-0.1640129, 51.522393],
                bearing: 0,
                pitch: 45,
                zoom: 16
            },
            animationSpeed: 0.5
        },
        {
            name: 'aylesbury',
            options: {
                container: 'aylesburyOfficeMap',
                style: s4d.mapbox.defaultStyle,
                interactive: false
            },
            startPosition: {
                center: [-0.809735, 51.813605],
                bearing: 210,
                pitch: 0,
                zoom: 14
            },
            endPosition: {
                center: [-0.8117802, 51.8163058],
                bearing: 0,
                pitch: 45,
                zoom: 16
            },
            animationSpeed: 0.5
        }
    ];

    // Create each map
    s4d.maps.forEach(function(map){

        for (var key in map.startPosition){
            if (map.startPosition.hasOwnProperty(key)){
                map.options[key] = map.startPosition[key];
            }
        }

        var mapContainer = document.getElementById(map.options.container)

        if (mapContainer) {

            if(!map.created){

                // Create the map
                map.mapboxControl = new mapboxgl.Map(map.options);

                // Create a marker
                var el = document.createElement('div');
                el.className = 'marker';

                new mapboxgl.Marker(el, { offset: [-53 / 2, -62 / 2] })
                    .setLngLat(map.endPosition.center)
                    .addTo(map.mapboxControl);

                map.created = true;
            }
            
        }

    });


    // Check to see if maps have entered the screen
    window.addEventListener('scroll', function() {

        s4d.maps.forEach(function(map){

            var mapContainer = document.getElementById(map.options.container)

            if (map.created && mapContainer && s4d.isElementOnScreen(mapContainer) ) {
                 
                // Start the animation
                s4d.mapbox.startMapAnimation(map);
                
            }
        });
        
    });


    // Start Map Animation
    s4d.mapbox.startMapAnimation = function(map) {
        if(!map.animationDone){
            map.mapboxControl.flyTo({
                center: map.endPosition.center,
                zoom: map.endPosition.zoom,
                bearing: map.endPosition.bearing,
                pitch: map.endPosition.pitch,
                speed: map.animationSpeed,
                curve: 1,
                easing: function(t){
                    return Math.sin(t * Math.PI / 2);
                }
            });
            map.animationDone = true;
        }
    }


})(window.s4d = window.s4d || {}, jQuery);