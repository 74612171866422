(function () {

    var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth,
        y = w.innerHeight || e.clientHeight || g.clientHeight;

    var a = {
        screenWidth: screen.width,
        screenHeight: screen.height,
        viewportWidth: x,
        viewportHeight: y
    };

    /*var request = new XMLHttpRequest();
    request.open('POST', '/umbraco/surface/s4dt/Update', true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    request.send(JSON.stringify(a));*/

    serialize = function (obj) {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    a = serialize(a);

    gf = function (a, b) {
        var c = '/umbraco/surface/s4dt/Update?';
        var d = new Image(1, 1);
        d.src = c + a;
        d.onload = function () {
            d.onload = null;
            d.onerror = null;
            b()
        };
        d.onerror = function () {
            d.onload = null;
            d.onerror = null;
            b()
        }
    }

    df = function (a, b) {
        var c = window.XMLHttpRequest;
        if (!c) return !1;
        var d = new c;
        if (!("withCredentials" in d)) return !1;
        d.open("POST", '/umbraco/surface/s4dt/Update', !0);
        d.withCredentials = !0;
        d.setRequestHeader("Content-Type", "text/plain");
        d.onreadystatechange = function () {
            4 == d.readyState && (b(), d = null)
        };
        d.send(a);
        return !0
    };

    ef = function (a, b) {
        /*if (0 != Ne().indexOf(document.location.protocol)) return !1;
        var c;
        c = W.XDomainRequest;
        if (!c) return !1;
        c = new c;
        c.open("POST", Ne() + "/p/__utm.gif");
        c.onerror = function () {
            b()
        };
        c.onload = b;
        c.send(a);
        return !0*/
        return !1;
    };

    Ee = function (a, b) {
        /*if (!document.body) return We(function () {
            Ee(a, b)
        }, 100), !0;
        a = encodeURIComponent(a);
        try {
            var c = document.createElement('<iframe name="' + a + '"></iframe>')
        } catch (d) {
            c = document.createElement("iframe"), c.name = a
        }
        c.height = "0";
        c.width = "0";
        c.style.display = "none";
        c.style.visibility = "hidden";
        var e = Ne() + "/u/post_iframe.html";
        Ga(W, "beforeunload", function () {
            c.src = "";
            c.parentNode && c.parentNode.removeChild(c)
        });
        setTimeout(b, 1E3);
        document.body.appendChild(c);
        c.src = e;
        return !0*/
        return !1;
    };

    function Fa() { }

    function b() {
        //todo: implement error handler
    }

    Ce = function(a) {
        this.name = "len";
        this.message = a + "-8192"
    }

    De = function(a) {
        this.name = "ff2post";
        this.message = a + "-2036"
    }

    b = b || Fa;
    if (d || 2036 >= a.length) gf(a, b);
    else if (8192 >= a.length) {
        if (0 <= window.navigator.userAgent.indexOf("Firefox") && ![].reduce) throw new De(a.length);
        df(a, b) || ef(a, b) || Ee(a, b) || b()
    } else throw new Ce(a.length);

})();