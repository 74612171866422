////////////////////////////////////////
// Buttons
////////////////////////////////////////

(function(s4d, $, undefined) {


    // Handle clicks on scroll to contact form buttons
    var contactButtons = document.querySelectorAll('[button-action="scroll-to-contact-form"]');

    Array.prototype.forEach.call(contactButtons, function(el, i){

        el.addEventListener('click', function(event){

            event.preventDefault();

            var currentPosition = window.pageYOffset || document.documentElement.scrollTop;

            var contactFormBox = document.querySelector('.contact-form').getBoundingClientRect();
            var contactFormYPosition = contactFormBox.top + currentPosition;

            var difference = Math.sqrt(Math.pow((contactFormYPosition - currentPosition), 2));
            var duration = Math.sqrt(difference) * 15;

            TinyAnimate.animate(currentPosition, contactFormYPosition -200, duration, function(value){
                document.documentElement.scrollTop = document.body.scrollTop = value;
            }, 'easeOutQuad');

        });

    });

    //See s4dtFooter for CallPage button code
    var callMeBackButtons = document.querySelectorAll('[button-action="call-me-back"]');
    Array.prototype.forEach.call(callMeBackButtons, function(el, i){
        el.style.display = 'none';
    });


})(window.s4d = window.s4d || {}, jQuery);